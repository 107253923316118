<template>
<!-- 其它捐赠公示 -->
  <div class="otherdonation">
    <Newslist :list="otherdonationlist" :nodename="'xxgk_qtjxxx'" @childByValue="childByValue"></Newslist>
  </div>
</template>

<script>
import Newslist from '@/components/newslist.vue'
export default {
  props: {
    otherdonationlist: {
      type: Array,
      default: () =>[]
    }
  },
  components: {
    Newslist
  },
  name: 'OtherDonation',
  data () {
    return { 
    }
  },
  methods: {
    childByValue (childByValue,nodename) {
      this.$emit('childByValue', childByValue,nodename)
    }
  }
}
</script>

<style lang="less" scoped>
.otherdonation {
    width: 908px;
    padding: 39px 40px 41px 40px;
}
</style>
