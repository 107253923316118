<template>
  <!-- 工作报告内容 -->
  <div class="work">
      <!-- 工作报告内容 -->
      <Newslist :list="worklist" :nodename="'xxgk_gzbg'" @childByValue="childByValue"></Newslist>
  </div>
</template>

<script>
import Newslist from '@/components/newslist.vue'
export default {
  name: 'Work',
   props: {
    worklist: {
      type: Array,
      default: () =>[]
    }
  },
  components: {
    Newslist
  },
  methods : {
    childByValue (childByValue,nodename) {
      this.$emit('childByValue', childByValue,nodename)
    }
  }
}
</script>

<style lang="less" scoped>
.work {
  width: 908px;
  padding: 39px 40px 41px 40px;
}
</style>
