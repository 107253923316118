<template>
  <div class="finace">
     <Newslist :list="fiancelist" :nodename="'xxgk_cwgs'" @childByValue="childByValue"></Newslist>
  </div>
</template>

<script>
import Newslist from '@/components/newslist.vue'
export default {
    props: {
    fiancelist: {
      type: Array,
      default: () =>[]
    }
  },
  components: {
    Newslist
  },
  name: 'Finance',
  data () {
    return { 
    }
  },
  methods: {
    childByValue (childByValue,nodename) {
      this.$emit('childByValue', childByValue,nodename)
    }
  }
}
</script>

<style lang="less" scoped>
.finace {
    width: 908px;
    padding: 39px 40px 41px 40px;
}

</style>
